/*soglia 1 */
.pZone{

cursor:pointer;


}
.pZone:hover {
    background-color: #00a94e;
    color: white;
    transition: 500ms;
  }
.soglia1{

    background-color: #00a94e!important;
    color: white !important;
    font-weight: bold!important;
}

.soglia1 .iconPlcData{
    background-color: #00a94e!important;
    border :1px solid white!important;
   
}

.soglia1 .MuiCardHeader-subheader{
    color: white !important;
    font-weight: bold;

}
/*soglia 2 */
.soglia2{
    color: white !important;
    background-color: #febe1e!important;
    font-weight: bold;
}

.soglia2 .iconPlcData{
    
    background-color: #febe1e!important;
    border :1px solid white!important;
}

.soglia2 .MuiCardHeader-subheader{
    color: white !important;
    font-weight: bold;
}
/*soglia 3 */

.soglia3{
    color: white !important;
    background-color: red!important;
    font-weight: bold;
}

.soglia3 .iconPlcData{
    background-color: red!important;
    border :1px solid white!important;
}

.soglia3 .MuiCardHeader-subheader{
    color: white !important;
    font-weight: bold;
}
/*soglia 4 */
.soglia4{
    color: white !important;
    background-color: #7b002c!important;
    font-weight: bold;
}


.soglia4 .iconPlcData{
    background-color: #7b002c!important;
    border :1px solid white!important;
}

.soglia4 .MuiCardHeader-subheader{
    color: white !important;
    font-weight: bold;
}

.carousel-status{
    display:none;
}
.carousel-root{
    display:none;
}
.mobileCardMeteo{
    display:block;
}
.carousel .control-dots .dot{
    display:"none!important";
}

.download-table-xls-button{
     border: 0;
    
    cursor: pointer;

    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #00a94e;
    color: white;
    margin-top: 30px;
    margin-left: 10px;
}
.download-table-xls-button:hover{
    background-color: green;
    transition: 400ms;
}


@media screen and (max-width: 800px) {
.carousel-root{
    display:block;
    margin-bottom: -55px;
}
.control-dots{
    display:"none!important";
}
.mobileCardMeteo{
    display:none;
}
  }